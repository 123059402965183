<template>
	<div class="row mt-md-5 mb-5" v-if="trip?.id">
		<ExperienceHeader :title="trip.name" />
		<div class="col-12 mb-2" v-if="Array.isArray(trip.images)">
			<img :src="`${storageBucket}/events%2F${trip.id}%2F${trip.images[0]}?alt=media`" class="img-fluid rounded" />
		</div>
		<div class="col-12">
			<div class="row">
				<div class="col-12 py-2 mb-5 border-bottom bg-white sticky-top" style="z-index: 1035">
					<div class="row">
						<div class="col-lg">
							<span class="text-secondary">from:</span>
							<h4>{{ formatCurrency(trip.base_price) }} (USD)</h4>
						</div>
						<div class="col-lg-4 d-grid">
							<button class="btn btn-dark" @click="$router.push(`/travel-dates/${trip.id}`)">
								Dates and prices
							</button>
						</div>
					</div>
				</div>
				<div class="col-lg-8 mb-5">
					<div class="mb-5" v-html="trip.description"></div>
					<div>
						<h3 class="fw-bold">What's Included</h3>
						<div class="mb-5" v-html="trip.whats_included"></div>
					</div>
					<!-- <div>
						<h3 class="fw-bold mb-4">Your Itinerary</h3>
						<template v-for="index in Object.keys(trip?.itinerary||{}).length" :key="index">
							<div class="row ms-2 mb-4">
								<div class="col-auto text-center fw-bold lh-1">
									<span class="fs-6">DAY</span><br/>
									<span class="fs-4">{{ index < 10 ? `0${index}` : index }}</span>
								</div>
								<div class="col">
									<div class="fw-bold fs-4 lh1" style="line-height: 1;" v-html="trip.itinerary[`day_0${index}`].title"></div>
									<div class="my-2" v-html="trip.itinerary[`day_0${index}`]?.description"></div>
								</div>
							</div>
						</template>
					</div> -->
				</div>
				<div class="col-lg-4">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-light shadow-lg d-grid">
								<p>Group size: {{ trip.group_size }} people <br />Min. age: {{ trip.min_age }} years</p>
								<a href="https://api.whatsapp.com/send?phone=18683882937" target="_whatsapp"
									class="btn btn-outline-dark mt-3 mb-4">
									Speak with a Soca Islands Rep
								</a>
								<p>
									Have questions before you book?
									<br />Read fine print here.
								</p>
							</div>
						</div>
					</div>
					<div class="row my-4">
						<WhyBook />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-md-5" v-else></div>
</template>
<script>
import ExperienceHeader from "../components/common/ExperienceHeader.vue";
import WhyBook from "../components/common/WhyBook.vue";
import { destinations, experience_categories, custom_experiences } from "../data";
import { formatCurrency } from "../utils";
export default {
	name: "Trip",
	components: { WhyBook, ExperienceHeader },
	computed: {
		tripId() {
			return this.$route.params.trip_id;
		},
		trip() {
			const eventsList = this.$store.state.eventsList;
			const trip = eventsList?.find((event) => event.id === this.tripId);
			return trip;
		},
		storageBucket() {
			return this.$store.state.storageBucket;
		},
		pageHeader() {
			const { experience_categories, tripCategory } = this;
			const pageHeader = experience_categories?.find((category) => category.id === tripCategory)?.name;
			return pageHeader;
		},
	},
	data() {
		return {
			formatCurrency,
			destinations,
			experience_categories,
			custom_experiences,
			isBusy: false,
			recentlyViewed: [],
		};
	},
	mounted() {
		this.$store.commit("setPageTitle", "Explore");
	},
};
</script>
