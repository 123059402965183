<template>
	<div class="row mt-md-5">
		<ExperienceHeader :title="pageHeader" />
		<template v-for="event in eventsList" :key="event.id">
			<EventItem :event="event" />
		</template>
		<div class="text-center text-secondary" v-if="eventsList.length < 1">
			<em>No events found for this destination... </em>
		</div>
	</div>
</template>
<script>
import ExperienceHeader from '../components/common/ExperienceHeader.vue';
import EventItem from '../components/experiences/EventItem.vue';
import { destinations } from '../data';
import { formatCurrency } from '../utils';
export default {
	name: 'Destinations',
	components: {
		EventItem,
		ExperienceHeader,
	},
	computed: {
		destinationId() {
			return this.$route.params.destination_id;
		},
		eventsList() {
			const eventsList = this.$store.state.eventsList;
			const filteredEventsList = eventsList?.filter((event) => event?.destinations.includes(this.destinationId));
			return filteredEventsList;
		},
		storageBucket() {
			return this.$store.state.storageBucket;
		},
		pageHeader() {
			const { destinations, destinationId } = this;
			const pageHeader = destinations?.find((destination) => destination.id === destinationId)?.name;
			return pageHeader;
		},
	},
	data() {
		return {
			formatCurrency,
			destinations,
			isBusy: false,
			recentlyViewed: [],
		}
	},
	mounted() {
		this.$store.commit("setPageTitle", "Destinations - Soca Islands")
	},
}
</script>