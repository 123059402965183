<template>
	<div class="row mt-md-5">
		<ExperienceHeader :title="pageHeader" />
		<template v-for="event in eventsList" :key="event.id">
			<EventItem :event="event" />
		</template>
		<div class="text-center text-secondary" v-if="eventsList.length < 1">
			<em>No events found for this category... </em>
		</div>
	</div>
</template>
<script>
import ExperienceHeader from '../components/common/ExperienceHeader.vue';
import { orderBy } from 'lodash';
import EventItem from '../components/experiences/EventItem.vue';
import { experience_categories } from '../data';
import { formatCurrency } from '../utils';
export default {
	name:'TripCategory',
	components: {
		EventItem,
		ExperienceHeader,
	},
	computed:{
		tripCategory(){
			return this.$route.params.trip_category;
		},
		eventsList(){
			const eventsList = this.$store.state.eventsList;
			const filteredEventsList = eventsList?.filter((event) => event.experience_category === this.tripCategory);
			return orderBy(filteredEventsList, ["sort_order"], ["asc"]);
		},
		storageBucket(){
			return this.$store.state.storageBucket;
		},
		pageHeader(){
			const { experience_categories, tripCategory } = this;
			const pageHeader = experience_categories?.find( (category) => category.id === tripCategory )?.name;
			return pageHeader;
		},
	},
	data(){
		return{
			formatCurrency,
			experience_categories,
			isBusy: false,
			recentlyViewed: [],
		}
	},
	mounted(){
		this.$store.commit("setPageTitle", "Explore")
	},
}
</script>