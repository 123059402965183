<template>
	<div class="row mt-md-5 mb-2">
		<div class="col-auto mt-2 h2 pointer" @click="$router.go(-1)">
			<i class="bi bi-chevron-left"></i>
		</div>
		<div class="col p-1 h3 mb-3">
			<h1 class="fw-bold">{{ title }}</h1>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ExperienceHeader',
	props: {
		title:{
			type: String,
			default: "",
		}
	},
}
</script>